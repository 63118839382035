<template>
  <TargetKpiKorporatForm mode="Detail" module="Target KPI Korporat"> </TargetKpiKorporatForm>
</template>

<script>
import TargetKpiKorporatForm from './form-target';

const TargetKpiKorporatUpdate = {
  name: 'TargetKpiKorporatUpdate',
  components: { TargetKpiKorporatForm },
};

export default TargetKpiKorporatUpdate;
</script>
